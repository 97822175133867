  <template>
  <div class="pagination-container">
    <div class="pagination-container__pagination">
      <button
        class="btn-previous btn"
        :class="{ 'disable-btn': pageNumber <= 1 }"
        @click="moveToFirstPage()"
      >
        <div class="dubble-arrow">
          <span class="icon-forward prev"></span>
          <span class="icon-forward prev"></span>
        </div>
      </button>
      <button
        class="btn-previous btn"
        :class="{ 'disable-btn': pageNumber <= 1 }"
        @click="onChange('prev')"
      >
        
          <span class="icon-forward prev"></span>Previous
      </button>
      <h2 class="counter">{{ pageNumber }}-{{ totalPages }}</h2>
      <button 
       :class="{ 'disable-btn': disableNext }"
       class="btn-next btn" @click="onChange('next')">
        Next<span class="icon-forward next"></span>
      </button>
      <button 
       :class="{ 'disable-btn': disableNext }"
       class="btn-next btn" @click="moveToLastPage()">
        <div class="dubble-arrow">
          <span class="icon-forward next"></span>
          <span class="icon-forward next"></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articlesPerPage: 10
    };
  },
  props: {
    results: Number,
    pageNumber: Number,
    totalPages: Number,
    pageSize:{
      default: ()=>(9),
      type: Number
    },
    hasMoreContent:{
      deafult: () => (false),
      type: Boolean
    },
    checkContentLimit:{
      default: () => (false),
      type: Boolean
    }
  },
  emits: ["change-page"],
  computed:{
    disableNext(){
      if (this.checkContentLimit){
        return !this.hasMoreContent
      }
      else {
        return this.results < this.pageSize
      }
    }
  },
  methods: {
    onChange(page) {
      this.$emit("change-page", page);
    },
    moveToFirstPage() {
      this.$emit("move-first-page");
    },
    moveToLastPage() {
      this.$emit("move-last-page");
    }
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  &__pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    .btn {
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      cursor: pointer;
      display: flex;
      align-items: center;
      @include respond(phone-x-small) {
        font-size: 2rem;
      }
      .icon-forward {
        font-size: 1.5rem;
        color: $color-primary;
        margin: 0 0.7rem;
        @include respond(phone-x-small) {
          font-size: 2rem;
        }
      }
      .prev {
        display: inline-block;
        font-size: 1.5rem;
        transform: rotate(180deg);
        @include respond(phone-x-small) {
          font-size: 2rem;
        }
      }
      .dubble-arrow {
        margin: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-forward {
          margin: 0 -0.4rem;
          font-size: 1.5rem;
        }
        @include respond(phone-x-small) {
          margin: 0 1.5rem;
          .icon-forward {
            font-size: 1.8rem;
            margin: 0 -0.5rem;
          }
        }
      }
    }
    .counter {
      font-size: 1.6rem;
      margin-bottom: 0;
      margin: 0 2rem;
      color: $color-primary;
      font-family: $font-primary-medium;
    }
    .disable-btn {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
</style>
