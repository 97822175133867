<template>
  
  <textarea v-if="this.workingOn === 'newsContentIncludedSources'" :id="workingOn" :readonly="readonly" :placeholder="placeholder"  >
  </textarea>
  <textarea v-else-if="workingOn === 'researchPublicationsIncludedSources'"  :id="workingOn" :readonly="readonly" :placeholder="placeholder" >
  </textarea>
  <textarea v-else :id="workingOn" :readonly="readonly" :placeholder="placeholder" >
  </textarea>
</template>

<script>
import "@yaireo/tagify/dist/tagify.css";
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import {mapGetters} from 'vuex'

export default {
  name: "Tags",

  props: {
    // options: Array,
    prevTags: Array,
    placeholder: String,
    workingOn: String,
    isSubmitted: Boolean,
    allItems: Array,
    blackList: Array,
    readonly: {
      type: Boolean,
      default:false
    },
    removeFields:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      whitelist: [],
      selected: [],
      selectedUsers: [],
      allFetchedItems: this.allItems ? [...this.allItems] : [],
    };
  },
  emits: ["search-change"],
  computed: {
    ...mapGetters('discover',["sidebarSearchFilterArray","researchPublicationsSidebarSearchFilterArray"]),
    getItems() {
      if (this.workingOn === "locations")
        return this.$store.getters["tagify/getLocations"];
      else if (this.workingOn === "journalists") {
        return this.$store.getters["tagify/getJournalists"];
      } 
      else if (this.workingOn === "connections") {
        return this.$store.getters["profile/getConnections"];
      }
       else return true;
    },
    getWhitelist() {
      // console.log("this.getItems", this.getItems);
      this.getItems.map(
        (obj) => this.allFetchedItems && this.allFetchedItems.push(obj)
      );
      // console.log("this.allFetchedItems", this.allFetchedItems);
      // console.log("this.allItems", this.allItems);
      // console.log("this.allFetchedItems", this.allFetchedItems);
      this.$emit("all-fetched-items", this.allFetchedItems,this.workingOn);

      this.getItems.map((el) => {
        this.whitelist.push({ value: el.label, icon: el.icon , id: el.value, email: el.key });
      });

      return this.whitelist;
    },
    prevTagsComplete() {
      // console.log("prevtags func");
      // console.log("this.options",this.options);
      // console.log("this.prevTags",this.prevTags);
      const temp = [];
      // console.log("this.allFetchedItems", this.allFetchedItems);
      // console.log("this.prevTags", this.prevTags);
      // console.log("this.allItems", this.allItems);
      this.allItems &&
        this.allItems.map((option) => {
          this.prevTags &&
            this.prevTags.map((prevTag) => {
              if (option.value === prevTag)
                temp.push({ value: option.label, label: option.value });
              // return true
            });
        });
      if (temp.length === 0) this.tagify.loading(false);
      // console.log("temp", temp);

      const temp2 = temp.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === value.value && t.label === value.label
          )
      );
      // console.log("temp2", temp2);
      return temp2;
    },
    newsIncludeSources() { 
      const tags = this.sidebarSearchFilterArray.map(filter => { 

      return { "label": filter.value, "value": filter.label }
        
      })
      return JSON.stringify(tags)
    },
    researhPublicationsIncludedSources() { 
      // restructuring the objects according to the requirements
      const tags = this.researchPublicationsSidebarSearchFilterArray.map(filter => {

        return { "label": filter.value, "value": filter.label }

      })
      return JSON.stringify(tags) // returning a strigified array of objects

    }
  },
  methods: {
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    tagTemplate(tagData) {
      // console.log(this.selected);
      // console.log("tagData", tagData);
      let alreadyIncluded=false
      this.selectedUsers.map((user)=>{
        if (user===tagData.value){
          alreadyIncluded=true
        }
      })
      if (!alreadyIncluded) this.selectedUsers.push(tagData);
      this.$emit("fetchSelection", this.selectedUsers);
      return `
        <tag title="${tagData.value}"
                contenteditable='false'
                spellcheck='false'
                tabIndex="-1"
                class="tagify__tag ${tagData.class ? tagData.class : ""}"
                ${this.getAttributes(tagData)}>
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div>
              ${
                tagData.icon
                  ? `
                <div class='tagify__tag--avatar-wrap'>
                    <img onerror="this.style.visibility='hidden'" src="${tagData.icon}">
                </div>
                `
                  : ""
              }

                <span class='tagify__tag--tag-text'>${tagData.value}</span>
            </div>
        </tag>
    `;
    },
    getAttributes(tagData) {
      const attributes = [];
      for (const [key, value] of Object.entries(tagData)) {
        if (key !== "value" && key !== "email" && key !== "class") {
          attributes.push(`${key}="${value}"`);
        }
      }
      return attributes.join(" ");
    },
    suggestionItemTemplate(tagData) {
      return `
        <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item ${
              tagData.class ? tagData.class : ""
            }'
            tabindex="0"
            role="option">

            ${
              tagData.icon
                ? `
                    <div class='tagify__dropdown__item__avatar-wrap me-2'>
                        <img onerror="this.style.visibility='hidden'" class="rounded-circle w-50px me-2" src="${tagData.icon}">
                    </div>`
                : ""
            }

            <div class="tagify__dropdown__item__name">
                <p>${tagData.value}</p>
            </div>
        </div>
    `;
    },
    dropdownHeaderTemplate(suggestions) {
      return `
        <header data-selector='tagify-suggestions-header' class="${
          this.settings.classNames.dropdownItem
        } ${this.settings.classNames.dropdownItem}__addAll">
            <div>
                <strong>${
                  this.value.length ? `Add Remaning` : "Add All"
                }</strong>
                <a class='remove-all-tags'>Remove all</a>
            </div>
            <span>${suggestions.length} members</span>
        </header>
    `;
    },
  },
  watch: {
    isSubmitted(val) {
      if (val) {
        this.tagify.removeAllTags();
        this.allFetchedItems = [];
        this.selected = [];
        this.selectedUsers = [];
        this.whitelist = [];
      }
    },
    blackList(val) {
      // console.log('blacklist chANGES', val);
      // this.changedBlackList=val
      // console.log("this.changedBlackList",this.changedBlackList);
      this.tagify.settings.blacklist = val;
      // console.log("this.tagify.blacklist",this.tagify.blacklist);
    },
    // fetchBlacklist(val){
    //   this.tagify.blackList = val
    //   console.log("fetchBlacklist worked");
    // }
    removeFields(val) {
      if (val) {
        this.tagify.removeAllTags()
      }
    },
    // sidebarSearchFilterArray: {
    //   handler(val) {
    //     console.log(val)
    //     const tags = this.sidebarSearchFilterArray.map(filter => {
    //       return { "label": filter.value, "value": filter.label }

    //     })
    //     this.newsSideBarFilterTags = JSON.stringify(tags)
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    // console.log(this.blackList);
    this.tagify = new Tagify(this.$el, {
      enforceWhitelist: false,
      editTags: false,
      dropdown: {
        enabled: 0,
        closeOnSelect: false,
        classname: "users-list",
      },
      templates: {
        tag: this.tagTemplate,
        dropdownItem: this.suggestionItemTemplate,
        dropdownItemNoMatch: function(data) {
          return `<div class='${this.settings.classNames.dropdownItem}' value="noMatch" tabindex="0" role="option">
                    No suggestion found for: <strong>${data.value}</strong>
                </div>`;
        },
      },
      // blacklist: this.changedBlackList.slice(),
    });
    this.tagify
      .on("add", onAddTag.bind(this))
      .on("remove", onRemoveTag.bind(this))
      .on("input", onInput.bind(this))
      .on("focus", onSelectFocus.bind(this));

    this.tagify.whitelist = this.prevTagsComplete;
    this.prevTags && this.selected.push(...this.prevTags);
    this.tagify.addTags(this.prevTagsComplete);
    const mockAjax = (() => {
      let timeout;
      return (duration) => {
        clearTimeout(timeout);
        return new Promise((resolve) => {
          timeout = setTimeout(
            () => resolve(this.getWhitelist),
            duration || 3000
          );
        });
      };
    })();

   
    function onSelectFocus() { 
       if (this.workingOn ==="newsContentIncludedSources" || this.workingOn === "newsContentExcludedSources" || this.workingOn === "researchPublicationsIncludedSources" || this.workingOn === "researchPublicationsExcludedSources") {
        this.tagify.whitelist.length = 0;
         mockAjax().then(
          function (result) {
            if (result.length !== 0) {
              this.whitelist = [];
              this.tagify.whitelist.push(
                ...this.getWhitelist,
                ...this.tagify.value
              );

              this.tagify
                .loading(false)
                .dropdown.show.call(this.tagify, '');
            } else {
              setTimeout(() => {
                this.whitelist = [];
                this.tagify.whitelist.push(
                  ...this.getWhitelist,
                  ...this.tagify.value
                );
                this.tagify
                  .loading(false)
                  .dropdown.show.call(this.tagify, '');
              }, 500);
            }
          }.bind(this)
        );
      }
    }

    function onAddTag(e) {
      // console.log(this.getItems);
      // console.log(this.changedBlackList);
      this.getItems.map((el) => {
        // console.log("el",el);
        if (el.label === e.detail.data.value) {
          // console.log(el.label ,"===", e.detail.data.value);
          // console.log(el.value);
          // console.log("PUSH");
          
          this.selected.push(el.value);

          
          this.selected = this.selected.filter(function(value, index, array) {
            return array.indexOf(value) === index;
          });
        }
        // console.log(this.selected);
      });
      // console.log("This.selected", this.selected);
      this.$emit("send-selections", this.selected, this.workingOn);
    }

    function onRemoveTag(e) {

      if (this.allFetchedItems) {
        this.allFetchedItems.map((el) => {
          if (el.label === e.detail.data.value) {
            this.selected = this.selected.filter((value) => value !== el.value);
            this.selectedUsers = this.selectedUsers.filter((value) => value.id !== el.value);
          }
        });
      }
      if (this.workingOn === 'newsContentIncludedSources')
        this.$store.dispatch("discover/removeSidebarSearchFilters", e.detail.data.value)
      else if (this.workingOn === 'researchPublicationsIncludedSources')
        this.$store.dispatch("discover/removeSidebarResearchPublicationFilter", e.detail.data.value)
      else if (this.workingOn === 'connections')
      this.$emit("fetchSelection", this.selectedUsers);
      this.$emit("send-selections", this.selected, this.workingOn,);
    }

    function onInput(e) {
      // console.log("inputing")
      if (e.detail.value !== "") {
        if (this.workingOn !== 'newsContentIncludedSources' && this.workingOn && "newsContentExcludedSources" && this.workingOn !== "researchPublicationsIncludedSources" && this.workingOn !== "researchPublicationsExcludedSources")
        this.tagify.loading(true).dropdown.hide.call(this.tagify);

        this.debounce(() => {
          this.$emit(
            "search-change",
            e.detail.value,
            this.workingOn === 'newsContentIncludedSources' || this.workingOn === "newsContentExcludedSources" ? false : true
          );
          this.tagify.whitelist.length = 0;

          if (this.workingOn !== 'newsContentIncludedSources' && this.workingOn && "newsContentExcludedSources" && this.workingOn !== "researchPublicationsIncludedSources" && this.workingOn !== "researchPublicationsExcludedSources") {

            mockAjax().then(
              function (result) {
                if (result.length !== 0) {
                  this.whitelist = [];
                  this.tagify.whitelist.push(
                    ...this.getWhitelist,
                    ...this.tagify.value
                  );
                  // console.log(e.detail.value)
                  this.tagify
                    .loading(false)
                    .dropdown.show.call(this.tagify, e.detail.value);
                } else {
                  setTimeout(() => {
                    this.whitelist = [];
                    this.tagify.whitelist.push(
                      ...this.getWhitelist,
                      ...this.tagify.value
                    );
                    this.tagify
                      .loading(false)
                      .dropdown.show.call(this.tagify, e.detail.value);
                  }, 5000);
                }
              }.bind(this)
            );
          }

        }, 1000);
      }
    }

    // this.sidebarSearchFilterArray.map(filter => {
    //   const exists = this.selected.findIndex(selectedFilter => selectedFilter === filter.value)
    //   if(exists===-1)
    //     this.selected.push(filter.value)
    // })

    // this.$emit("send-selections", this.selected, this.workingOn);
    
  },
};
</script>
<style lang="scss">
.tagify {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 100%;
  background-color: $color-white;
  max-height: 14rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.5rem;
  &__input {
    max-width: 100%;
    min-width: 100%;
    font-family: $font-primary;
    font-size: 1.4rem;
    padding: 1.1rem;
    margin: 0;
    &::before {
      color: rgb(146, 146, 146);
      margin-top: 0.1rem;
    }
  }
  &__tag {
    margin: 0.5rem 0 0.5rem 0.5rem;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 var(--tag-inset-shadow-size,1.1em) #cfd7ef inset;
      div {
        &::before {
        box-shadow: 0 0 0 var(--tag-inset-shadow-size,1.1em) #cfd7ef inset !important;
      }
      }
    }
    .tagify__tag:focus div::before, .tagify__tag:hover:not([readonly]) div::before {
      box-shadow: 0 0 0 var(--tag-inset-shadow-size,1.1em) #cfd7ef inset !important;
    }
    .tagify__tag__removeBtn {
      padding: 1rem;
      width: 1.4rem;
      height: 1.4rem;
    }
    &--tag-text {
      font-family: $font-primary;
      font-size: 1.4rem;
      vertical-align: middle !important;
    }
    &--avatar-wrap {
      width: 1.8rem;
      height: 1.8rem; 
      transform: inherit !important;
      margin-right: 0.5rem; 
      img {
        border-radius: 100%;
        width: 100%;
        height: auto;
        background-color: rgb(171, 171, 171);
      }
    }
    &--tag-text {
      font-family: $font-primary;
      font-size: 1.2rem;
    }
  }
}
.tagify::-webkit-scrollbar {
  width: 0.2rem;
}

.tagify::-webkit-scrollbar-track {
  background-color: #c8c8c87d;
  border-radius: 0.8rem;
}

.tagify::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 0.8rem;
  height: 0.2rem;
}
.tagify__dropdown__item {
  display: flex;
  align-items: center;
  .tagify__dropdown__item__avatar-wrap {
    width: 3rem !important;
    height: 3rem !important;
    img {
      width: 100%;
      height: auto;
    }
  }
  .tagify__dropdown__item__name {
    display: flex;
    align-items: center;
    p {
      font-family: $font-primary;
      font-size: 1.6rem;
      margin-bottom: 0;
      line-height: 2rem;
    }
  }
}
</style>
