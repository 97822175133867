<template>
  <div class="request-news-source-container">
    <h3 class="title">Request additional sources if they are not listed to better tailor your content.</h3>
    <a-form layout="vertical">
      <a-form-item label-align="left">
        <label for="message">Type URL</label>
        <div class="https-field">
          <p>https://</p>
          <a-input
          placeholder="type source"
          required
          style="width: 100%"
          v-model:value="requestSource"
          @focusout="handleFocusout">
          </a-input>
        </div>
        <p v-if="urlValidate" class="error">URL is not valid</p>
        <p v-if="fieldErr" class="error">This field is required.</p>
        <p class="error" v-if="requestSourceErr">{{ requestSourceErr }}</p>
      </a-form-item>
      <a-form-item label-align="left">
        <label for="message">Type Message <span> (Optional)</span></label>
        <a-textarea
          name=""
          id=""
          rows="2"
          v-model:value="requestMessage"
        ></a-textarea>
      </a-form-item>
      <a-button
        class="btn-primary request-btn"
        @click="submitNewsSourceRequest"
        :loading="loading"
      >
        Submit
      </a-button>
    </a-form>
  </div>
</template>


<script>

export default {
  props: {
    visibleModal: Boolean,
    requestType: String,
  },
  watch: {
    visibleModal() {
      this.requestNewsSourceModal = this.visibleModal;
    },
  },
  mounted(){
      this.emitter.on('clearFields', (val) => {
        this.requestSource = "";
        this.urlValidate = false;
        this.fieldErr = false
      })
  },
  data() {
    return {
      requestNewsSourceModal: false,
      requestSourceErr: "",
      requestSource: "",
      requestMessage: "",
      urlValidate: false,
      fieldErr: false,
      loading: false,
      showMessageModal: false,
      message: {}
    };
  },
  emits: ["closeModal" ],
  methods: {
    closeRequestNewsSourceModal() {
      this.requestSourceErr = "";
      this.requestSource = "";
      this.requestMessage = "";
      this.$emit("closeModal");
    },
    handleFocusout() {
      this.requestSource = this.requestSource.replace(/^https?:\/\//, "");
      0;
      if (
        /^(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(this.requestSource)
      ) {
        this.urlValidate = false;
        this.fieldErr = false;
      } else if(/^(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+[/]/.test(this.requestSource)){
        this.urlValidate = false;
        this.fieldErr = false;
      }
      else {
        this.urlValidate = true;
        this.fieldErr = false;
      }
    },
    async submitNewsSourceRequest() {
      if (this.requestSource == "") {
        this.fieldErr = true;
        this.urlValidate = false;
        return false;
      }
      if (this.urlValidate == true) {
        return false;
      }
      try {
        this.loading = true;
        this.requestSourceErr = "";
        const authLink = "https://" + this.requestSource;
        const payload = {
          link: authLink,
          message: this.requestMessage,
          type: this.requestType
        };
        const response = await this.$store.dispatch(
          "discover/requestNewsSource",
          payload
        );
        if (response.status === 201) {
          this.closeRequestNewsSourceModal();
          this.emitter.emit('closeNewsSourceModal')
          this.emitter.emit('close-create-topic-drawer')
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.requestSourceErr = error.response.data.non_field_errors[0];
        this.loading = false;
      }
    },
  },
};
</script>


<style lang="scss">
.request-news-source-container {
  .ant-form {
    .ant-form-item {
      margin-bottom: 1.5rem;
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            label {
              margin-bottom: 0.8rem;
              display: inline-block;
              font-size: 1.4rem;
              font-family: $font-primary;
              span {
                opacity: 0.6;
              }
            }
            .ant-input {
              margin-bottom: 0;
            }
            .https-field {
              display: flex;
              align-items: center;
              width: 100%;
              border: 1px solid #c5c6cc;
              border-radius: 0.5rem;
              padding: 0 1rem;
              height: 4rem;
              background-color: $color-white;
              p {
                margin-bottom: 0;
                font-size: 1.4rem;
                font-family: $font-primary;
              }
              input {
                border: none;
                padding: 0;
                box-shadow: none;
              }
            }
            input {
              height: 100%;
              margin-bottom: 1.5rem;
              width: 100%;
              padding: 0 1.5rem;
              font-size: 1.4rem;
              font-family: $font-primary;
              outline: none;
              border: 1px solid #c5c6cc;
              &::placeholder {
                opacity: 0.4;
                color: $color-black;
              }
            }
            textarea {
              width: 100%;
              resize: none;
              box-shadow: none;
              padding: 1rem;
              &:hover {
                border-color: $color-dark-gray-4;
              }
            }
            .error {
              font-size: 1.2rem;
              margin-top: 0.3rem;
              margin-bottom: 0;
              font-family: $font-primary;
            }
          }
        }
      }
    }
    .request-btn {
      position: relative !important;
      border-radius: 0.5rem !important;
      width: 10rem;
      height: 3.6rem;
      font-family: "Roboto-medium";
      font-size: 1.4rem;
      background-color: $color-primary;
      margin-left: auto;
      margin-top: 3rem;
      border: none;
      color: $color-white !important;
      display: block;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: $color-white;
      }
    }
  }
  .title {
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
    font-family: $font-primary-medium;
    padding-right: 0.5rem;
  }
  
  .disable-submit-btn {
    pointer-events: none;
    background-color: gray;
    opacity: 0.5;
  }
}
</style>
