<template>
  <div class="engage-bg">
    <div class="engage">
      <div class="image-bg" ref="bg-img"></div>
      <the-header whiteColor>
        <template v-slot:right>
          <div class="header__right-navigation">
            <!-- <div class="weather-icon">
              <i
                class="icon navigation-icon icon-brightness weather-icon__icon"
              ></i>
              <p class="weather-icon__para">91.4°Fs</p>
            </div> -->
            <!-- <i
              class="icon navigation-icon icon-bookmark notification__icon"
            ></i> -->

            <!-- <router-link to="/workspace/write" class="btn btn-white"
              >Post Article</router-link
            > -->
            <navbar-search :engage="true"></navbar-search>
            <a-tooltip placement="bottom">
              <template #title>
                  <h1>Requesting a new source?</h1>
                  <p>In Glocal you can request more publication sources if you do not find them in our research source lists.</p>
              </template>
              <!-- <a-button
                class="navigation-btn"
                @click="visibleRequestModal()"
              >
              <img src="@/assets/icons/add.svg" alt="icon" class="plus-icon">
                Request a source
              </a-button> -->
            </a-tooltip>
            <a-button
                class="navigation-btn"
                @click="visibleInviteToGlocalModal()"
            >
            <img src="@/assets/icons/forward_to_inbox.svg" alt="icon" class="contact-icon">
            Invite Contacts
            </a-button>
            <div @click="showNotifications()" class="notification">
              <img src="@/assets/icons/notifications-icon.svg" 
                alt="icon"
                class="navigation-icon bell-icon"
              />
              <span
              v-if="isUserRequest || isNotifications"
                class="notifications-alert"
              ></span>
            </div>
            <img src="@/assets/icons/diversity-icon.png" alt="icon"
              @click="goToNetworkTab('network')"
              class="navigation-icon"
            />
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link">
                <img 
                :src="displayPicture"  
                alt="user"
                class="navigation-icon icon-account"
                />
              </a>
              <template #overlay>
                <accounts-dropdown></accounts-dropdown>
              </template>
            </a-dropdown>
          </div>
        </template>
      </the-header>
      <impact-location-filter-navigator></impact-location-filter-navigator>
      <!-- <search-input engage></search-input> -->
      <router-view></router-view>
    </div>
  </div>
  <notification-drawer
      :drawer-visibility="showNotificationDrawer"
      @close-drawer="showNotificationDrawer = false"
      :loading="loader"
  ></notification-drawer>
  <request-news-sources
      :visibleModal="requestNewsSourceModal"
      @closeModal="closeRequestNewsSourceModal"
  ></request-news-sources>
  <invite-to-glocal 
      :visibleModal="inviteToGlocalModal"
      @closeModal="closeInviteToGlocalModal">
  </invite-to-glocal>
</template>

<script>
import TheHeader from "../components/layout/ProfilePageHeader.vue";
// import SearchInput from "../components/BaseComponents/SearchBar/SeacrhInput.vue";
import NotificationDrawer from "../components/PdbComponents/Notifications.vue";
import AccountsDropdown from "../components/BaseComponents/AccountsDropdown.vue";
import NavbarSearch from "../components/BaseComponents/NavbarSearch.vue";
import RequestNewsSources from "../components/BaseComponents/RequestNewsSource.vue";
import InviteToGlocal from "../components/BaseComponents/InviteToGlocal.vue";
import ImpactLocationFilterNavigator from "../components/BaseComponents/ImpactLocationFilterNavigator.vue"

export default {
  components: {
    TheHeader,
    // SearchInput,
    NotificationDrawer,
    AccountsDropdown,
    NavbarSearch,
    RequestNewsSources,
    InviteToGlocal,
    ImpactLocationFilterNavigator

    // TheNavbar
  },
  data() {
    return {
      showNotificationDrawer: false,
      loader: true,
      requestNewsSourceModal: false,
      inviteToGlocalModal: false
    };
  },
  computed: {
    isUserRequest() {
      return this.$store.getters['profile/getUserRequestCount']
    },
    isNotifications() {
      return this.$store.getters['profile/getNotificationCount']
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    displayPicture(){
      return this.$store.getters["profile/userInformation"].profile.profile_picture;
    },
  },
  mounted() {
    this.$store.dispatch("profile/getArticleNotificationsCount");
  },
  methods: {
    changeImg(img) {
      this.$refs["bg-img"].style.backgroundImage = `url(${img})`;
    },
    goToNetworkTab(val) {
      this.$router.push({
        path: `/user/${this.userId}/`,
        query: {tab: val},
      });
    },
    showNotifications() {
      this.getFeatures();
      this.showNotificationDrawer = true
    },
    async getFeatures() {
      try {
        const response = await this.$store.dispatch(
            "article/getGlocalFeatures"
        );
        if (response.status === 200) {
          this.loader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    visibleRequestModal() {
      this.requestNewsSourceModal = true 
    },
    closeRequestNewsSourceModal() {
      this.requestNewsSourceModal = false;
    },
    visibleInviteToGlocalModal() {
        this.inviteToGlocalModal = true
    },
    closeInviteToGlocalModal() {
      this.inviteToGlocalModal = false
    }
  },
};
</script>

<style lang="scss" scoped>
.engage-bg {
  .engage {
    height: 100%;
    background-color: #1a1a1a;
    .header {
      background-color: transparent !important;

      &__right-navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        @include respond(laptop-small) {
          margin-left: 3rem;
        }
        .navigation-icon {
          color: $color-white;
          margin-left: 3.4rem;
          width: 2.8rem;
          height: auto;
          cursor: pointer;
          font-size: 3rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
        }
        .notification {
          position: relative;
          .bell-icon {
            width: 1.9rem;
          }
          .notifications-alert {
            position: absolute;
            content: "";
            width: 0.7rem;
            height: 0.7rem;
            background-color: red;
            border-radius: 100%;
            top: 2px;
            right: 2px;
          }
        }
       
        .icon-account {
          color: $color-black !important;
          border-radius: 100%;
          border: 0.2rem solid $color-black;
          object-fit: cover;
          font-size: 3rem;
          width: 5.2rem;
          background-color: #ebebeb;
        }
        .navigation-btn {
          color: $color-white;
          font-family: $font-primary-medium;
          font-size: 1.6rem;
          background-color: $color-primary;
          padding: 0 2rem;
          height: 3.8rem;
          margin-left: 2rem;
          line-height: normal;
          border: none;
          outline: none;
          text-align: center;
          @include respond(laptop-small) {
            display: none;
          }
          
          span {
            line-height: 1.8rem;
          }
          .plus-icon {
            width: 1.4rem;
            height: auto;
            margin-right: 1.4rem;
            margin-bottom: 0.35rem;
          }
          .contact-icon {
            width: 2rem;
            height: auto;
            margin-right: 0.9rem;
          }
        }
      }
    }
  }
}

.image-bg {
  background-image: url("../assets/images/impact-bg.png");
  background-repeat: no-repeat;
  -webkit-transition-property: background-image;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-out;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  -moz-box-shadow: inset -7rem 15rem 15rem #030512;
  -webkit-box-shadow: inset -7rem 15rem 15rem #030512;
  box-shadow: inset -7rem 10rem 15rem #030512;
}
</style>
